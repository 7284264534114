import React, {useRef,  useState, useContext, useEffect, useReducer } from "react";
import {
	Prompt
  } from "react-router-dom";
import "./Home.css";
import { history } from '../../_helpers/history'
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import 'bootstrap/dist/css/bootstrap.css';

// var Accordion = require('react-bootstrap').Accordion;
import Card from 'react-bootstrap/Card'
// import Button from 'react-bootstrap/Button'
import { Button, Toast } from 'react-bootstrap';
import Header from '../../common/header/header';
import { CommonService } from '../../service/common.service';
import { useAlert } from "react-alert";
// import PDFViewer from './../../pdf-viewer/components/pdf-viewer-reactjs'
// import PDFViewer from 'pdf-viewer-reactjs'
import Dropdown from 'react-bootstrap/Dropdown'
import PdfComponent from './pdf';
import LoadingOverlay from 'react-loading-overlay';
import queryString from 'query-string';
import MessageComponent from '../../components/MessageComponent';
import OngoinClassStudentsComponent from '../../components/OngoinClassStudentsComponent';
import TestComponent from '../../components/test/testComponent'
import QuizComponent from "../../components/quiz/quizComponent";
import Jitsi from 'react-jitsi'
import Loader from '../../components/jitsiLoader'
import * as moment  from "moment";
import shareScreenOn from './../../assets/fls-class/share-on-greenbg.png'
import shareScreenOff from './../../assets/fls-class/share-off-redbg.png'
import videoCallOn from './../../assets/fls-class/video-on-greenbg.png'
import videoCallOff from './../../assets/fls-class/video-off-redbg.png'
import micOn from './../../assets/fls-class/mic-on-greenbg.png'
import muteEveryone from './../../assets/fls-class/mute-everyone-mic.png'
import micOff from './../../assets/fls-class/mic-off-redbg.png'
import videoCallStarted from './../../assets/fls-class/call-end-redbg.png'
import moreOption from './../../assets/fls-class/more-redbg.png'
import videPlaceHolder from './../../assets/fls-class/video-placeholder.png'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import Modal from 'react-bootstrap/Modal'
import Countdown from 'react-countdown';
import Table from 'react-bootstrap/Table'
// const WebSocket = require('ws');
var Centrifuge = require("centrifuge");
import Student from './component/Student';
// import Teacher from "./component/Teacher";
import TeacherKurento from "./component/TeacherKurento";
import StudentKurento from "./component/StudentKurento";
import { useDispatch } from "react-redux";
import SessionQA from "../../common/modal/SessionQA";
var centrifugeApiToken;
// import { Beforeunload } from 'react-beforeunload';
const Bowser = require("bowser"); 
const Home = ({ match }) => {
    const [showQAModal, setShowQAModal] = useState(false)

	const videoRef = useRef(null)
	const [showToast, setShowToast] = useState(false);




	// const [state, dispatch] = useReducer(reducer);
	var studentArr = []
	const [courseTestId, setCourseTestId] = useState(''); 
	const [loading, setLoading] = useState(false);
	const [role, setRole] = useState('')
	const [loadVideo, setLoadVideo] = useState(false)
	const [defaultViewHeading, setDefaultViewHeading] = useState('')
	const [teacherData, setteacherData] = useState([]);
	const [studentData, setStudentData] = useState(false);
	const [onlineStudents, setOnlineStudents] = useState(0);
	const [showStudentList, setShowStudentList] = useState([]);
	const [courseName, setCourseName] = useState(''); 
	const [chapterData, setchapterData] = useState([]);
	const [selectedAssetPageIndex, setSelectedAssetPageIndex] = useState(null)
	const [lectureData, setLectureData] = useState([]);
	const [selectedAsset, setSelectedAsset] = useState(null)
	const [chapterKey, setChapterKey] = useState(0)
	const [lectureKey, setLectureKey] = useState(0)
	const [previouslectureKey, setPreviousLectureKey] = useState(0)
	const [url, setUrl] = useState("")
	const [presentationType, setPresentationType] = useState('ASSET')
	const [previousPresentationState, setPreviousPresentationState] = useState('ASSET')
	const alert = useAlert();
	const queryParams = queryString.parse(location.search)
	const courseId = match.params?.courseId
	const classId = match.params?.classId
	const teacherId = match.params?.teacherId
	const channelName = queryParams?.channel_name;
	const enableVideoCall = queryParams?.enable_video || false
	const [isMaximized, setIsMaximized] = useState(false);
	const [selectedTest, setSelectedTest] = useState('');
	const [selectedLecture, setSelectedLecture] = useState('');
	const [selectedQuiz, setSelectedQuiz] = useState('');
	const [displayName, setDisplayName] = useState('')
	const [userData, setUserData] = useState('')
	const [canJoinVideoConference, setcanJoinVideoConference] = useState(false)
	const [classList, setClassList] = useState(true);
	const [Class, setClassName] = useState('');
    const [StartTime, setStartTime] = useState('');
	const [scale, setScale] = useState(1);
    const [EndTime, setEndTime] = useState('');
	let centrifuge = null;
	let videoElement = null;
	let wsConnection = null;
	const [page, setPage] = useState(1);
	const [pageNum, setPageNum] = useState(0);
	const [jistiJwtToken, setJistiJwtToken] = useState(null);
	const [jistiScreenShareCoordinates,setJistiScreenShareCoordinates] = useState(JSON.stringify({"CROP_X" : 265, "CROP_Y" : 173, "CROP_W" : 900, "CROP_H" : 670, DEVICE_PIXEL_RATIO : 1 }))
	const [jitsiDomain, setJistiDomain] = useState(process.env.jitsiUrl);
	const [connectToJitsi, setConnectToJitsi] = useState(false);

	const [testModalShow, settestModalShow] = useState(false);
	const hideTestModal = () => settestModalShow(false);
	const showTestModal = async () => {
		settestModalShow(true);
	}
	const [teacherList, setTeacherList] = useState([]);
	const [classLeaveAlert, setclassLeaveAlert] = useState(false);
	const hideClassLeaveModal = () => setclassLeaveAlert(false);
	const showClassLeaveAlert = async () => {
		setclassLeaveAlert(true);
	}

	const [showWelcomeModal, setshowWelcomeModal] = useState(false);
	const hideWelcomModalFn = () => setshowWelcomeModal(false);
	const showWelcomeModalFn = async () => {
		setshowWelcomeModal(true);
	}
	const [validated, setValidated] = useState(false);
	const [enableTestTillTime, setEnableTestTime] = useState('');
	const [enableTestTillDate, setEnableTestDate] = useState('');
	const [jitsiRef, setJistsiRef] = useState("");
	const [isBlocking, setIsBlocking] = useState(true);
	const [isClassLeaved, setLeaveClass] = useState(false);
	const [isCallJoined, setCallJoined] = useState(false);
	const [isScreenShared, setIsScreenShared] = useState(false);
	const [isVideoCallStarted, setisVideoCallStarted] = useState(false);
	const [isVideoCamera, setIsVideoCamera] = useState(true);
	const [isMicOn, setMicOn] = useState(false);
	var isLoggedIn = localStorage.getItem('user') ? true : false;
	var isExpired = localStorage.getItem('user') ? false : true;

	const [isSubscribed, setisSubscribed] = useState(false);
	const [globalCentriSubscription, setGlobalCentriSubscription] = useState(null);
    const  handleChange = (newValue) => {
		setPageNum(newValue);
	}
	
	const [groupMessageArr, setGroupMessageArr] = useState([]);
	const [showChatBox, setShowChatBox] = useState(false);
	const [showMessageComponent, setShowMessageComponent] = useState(false);
	const [individualMessageArr, setIndividualMessageArr] = useState([]);
	const [showIndividualMessageComponent, setShowIndividualMessageComponent] = useState(false);
	const [newGroupMessage, setNewGroupMessage] = useState(false);
	const [newIndividualMessage, setNewIndividualMessage] = useState(false);
	const [newStudentMessage, setNewStudentMessage] = useState(false);
	const [newMessage, setNewMessage] = useState(false);
	

	const [newStudentChat, setNewStudentChat] = useState([]);
	const [broswerWarningMessage,setBrowserWarningMessage] = useState('')
	const [browserWarning, setBrowserWarning] = useState(false)
	const dispatchMessage = useDispatch();
	const [classStartTime, setClassStartTime] = useState('')
	const [classEndtime, setClassEndtime] = useState('')
	const [questions, setQuestions] = useState([])
	const [error , setError] = useState(false)
	const [question, setQuestion] = useState({})
	const [timeSplits, setTimeSplits] = useState([])
	const [userId, setUserId] = useState()


	const checkForUrl = async (assetData) => {
		let timeTenMinBefore = moment().subtract(10,'minutes')
		let isBeforeTenMin = moment(assetData.modified_timestamp).isSameOrBefore(timeTenMinBefore)
		setDefaultViewHeading(`Loading...`)
		if (assetData.one_drive_embed_url == null && !isBeforeTenMin) {
			setUrl(null)
			let courseData = await getCourseChapter()
			if (courseData.length > 0) {
				let newUrl = null
				try {
					newUrl = courseData[assetData['chIndex']].lectures[assetData['lectIndex']].assets[assetData['asstIndex']].one_drive_embed_url
				} catch (error) {
					newUrl = null
				}
				if (newUrl != null) {

					setUrl(newUrl)
				}else{
					checkForUrl(assetData)
				}
			}
		}else if(assetData.one_drive_embed_url == null){
			setUrl(null)
			setDefaultViewHeading(`File Not Uploaded.`)
		}else{
			setUrl(assetData.one_drive_embed_url)
			// excecuteJisticommand('toggleShareScreen','sample')
		}
	}

	const parseToken = (token) => {
		// parseJwt(token) {
			var base64Url = token.split('.')[1];
			var base64 = base64Url.replace('-', '+').replace('_', '/');
			return JSON.parse(window.atob(base64));
		// }
	}
	
	useEffect(() => {

		history.listen((location, action) => {
			if(centrifuge) {
				centrifuge.disconnect();
			}

			if(wsConnection) {
				wsConnection.close()
			}
		})
		var browser = Bowser.parse(window.navigator.userAgent);
		var navigatorInfo = window.navigator
		if(browser.browser.name == 'Safari' && navigatorInfo.maxTouchPoints == 0){
			// window.alert(`Maxtouchpoints : ${JSON.stringify(navigatorInfo.maxTouchPoints)}`)
			setBrowserWarningMessage('You should consider using Google Chrome for better user experience. We are still working to make our system work in safari.')
			setBrowserWarning(true)
		}
		if(!isLoggedIn) {
			setLoading(false)
			let url = `?redirect_url=${location.href}`
            history.push(`/${url}`)
		}else{
			let jwtToken = JSON.parse(localStorage.user)['jitsi_jwt_token'];
			var userDetails = JSON.parse(localStorage.getItem('user'))
			var accessToken = userDetails.access_token;
			var current_time = new Date().getTime() / 1000; 
			const jwt = parseToken(accessToken);
	        if (current_time > jwt.exp) { 
				isLoggedIn = false
				isExpired = true
				localStorage.clear()
				setIsBlocking(false)
				setTimeout(() => {
					history.push(`/`)
				}, 100);
        	}
			getCentrifugeApiToken().then(token => {
				centrifugeApiToken = token
			})
			setJistiJwtToken(jwtToken)
			setLoading(false);
			var data = {}
			if (localStorage.getItem('user') != null && localStorage.getItem('user') != undefined) {
				data = JSON.parse(localStorage.getItem('user'))
				// let isPurchased = checkIfUserPurchased(data.id)
				setRole(data.role ? data.role : '')
				if (data.id != teacherId) {
					setIsVideoCamera(false)
				}
				if(data.role == 'STUDENT'){
					showWelcomeModalFn()
					// window.alert('This course has educational videos, Please click on “Okay” to grant permission to play video in this session! ')
				}
				setDisplayName(data.name)
				if (data.role == 'STUDENT') {
					getSetStudentChannelActivity(channelName)
					getUserData(userDetails.id)
				}
				setUserData(data)
				if (!isSubscribed) {
				wsConnection = new WebSocket(process.env.webSocketUrl);
				
				wsConnection.onopen = function(event) {
					wsConnection.send(JSON.stringify({
						"method": "ClassJoin",
						"data": {
							"user_id": data.id,
							"class_id": classId,
						}
				  }))
				};
			    wsConnection.onmessage = (message) => {
				};
				subscribeCentrifuge(channelName, data.role, data.centri_token)
				setisSubscribed(true)
				}
			}
	
			
			getClassDetails(data);
			getTeachers();
			getStudents();
			// getCourseChapter();
			if (courseId) {
				getCourseChapter();
			}
			getGroupMessages()
			getIndividualMessages()
		}


		
	}, []);

	useEffect(() => {
		if (localStorage.getItem('user') != null && localStorage.getItem('user') != undefined) {
			const user =  JSON.parse(localStorage.getItem('user'))
			setUserId(user.id)
		}
	  }, []);
	  
	  useEffect(() => {
		getQuestions()
	  }, [userId])


	const getUserData = (id) => {

		CommonService.httpGetService(`user/user?id=${id}`)
			.then(async data => {
				if (data.length > 0) {
					const currentUserData = data[0];

					if (currentUserData.user_dl_updated_on && currentUserData.user_dl_url) {
						const updatedOnDate = moment(currentUserData.user_dl_updated_on);
						const todayDate = moment();

						if (!updatedOnDate.isSame(todayDate, 'day')) {
							hideWelcomModalFn()
							alert.error("Redirecting to Courses. Please upload your driving license.");
							setTimeout(() => {
								location.href = `${location.origin}/courses`
							}, 2000);
						} else {
							console.log(currentUserData, 'User Data');
						}
					} else {
						hideWelcomModalFn()
						alert.error("Redirecting to Courses. Please upload your driving license.");
						setTimeout(() => {
							location.href = `${location.origin}/courses`
						}, 2000);
					}
				}
			})
			.catch(error => {
				alert.error(error);
				setLoading(false);
			});
	};  


	const getClassDetails = (data) => {
		setLoading(true);
		CommonService.httpGetService(`admin-api/class/get-student-classes/${data.role}?class_id=${classId}&user_id=${data.id}`).then(res => {
			let class_name = res[0].name
			let start_time = res[0].start_date
			let end_time = res[0].end_date 
			let class_start_time = res[0].start_time
			let class_end_time = res[0].end_time
			
			setClassName(class_name)
			setDefaultViewHeading(class_name)
			setStartTime(start_time)
			setEndTime(end_time)
			setClassStartTime(class_start_time)
			setClassEndtime(class_end_time)
			// setData(res);
			setLoading(false);
		}, error => {
			// setData([])
			setLoading(false);
		})

	}

	const getGroupMessages = () => {
		console.log("step 2")
		try {
            CommonService.httpGetService(`admin-api/class-chat?channel=${channelName}_groupChat`)
                .then(data => {
					console.log("step 3")
					// dispatchMessage({type: "MESSAGE_ARRAY", payload: data})

					setGroupMessageArr(data)
					setShowMessageComponent(true)
					
                }, error => {
					console.error('Error in fetching group chat ', error)
                })
        }
        catch {
            // alert.error("Please fill valid details")
        }

	}
	const handleMessage = (message,userId,typeOfChat) => {
		let postObj = {
			"method": "publish",
			"params": {
				"channel": `${channelName}_notification_${typeOfChat}`,
				"data": {
					message : message,
					userId : userId
				}
			}
		}
		if(typeOfChat == 'student_individual'){
			getIndividualMessages();
		}
		else{
			getGroupMessages();
		}
		CommonService.publishToCentrifuge(postObj,centrifugeApiToken).then(res => {
			console.log(res)
		}, error => {

		})
	}
	
	const getIndividualMessages = () => {
		try {
            CommonService.httpGetService(`admin-api/class-chat?channel=${channelName}_student_chat_${JSON.parse(localStorage.getItem('user')).id}`)
                .then(data => {
					// dispatchMessage({type: "MESSAGE_ARRAY", payload: data})
					setIndividualMessageArr(data)
					setShowIndividualMessageComponent(true)
                }, error => {
                    console.error('Error in fetching individual chat ', error)
                })
        }
        catch {
            // alert.error("Please fill valid details")
        }

	}

	const subscribeCentrifuge = (channelName, userRole, token) => {
		centrifuge = new Centrifuge(CommonService.getCentrifugeUrl('ws'));
		centrifuge.setToken(token);
		centrifuge.on('connect', function (ctx) {
			console.log("centri connected", ctx);
		});

		centrifuge.on('disconnect', function (ctx) {
			// centrifuge.disconnect()
		});


		var subs = centrifuge.subscribe(channelName,  (ctx) => {

			if (userRole == 'TEACHER') {
				if(ctx.data.videoState != undefined){
					if (ctx.data.videoState == true) {
						setIsVideoCamera(!ctx.data.videoState)
					}
				}
				else{
					var loggedInUser = JSON.parse(localStorage.getItem('user'))
					if(ctx.data.loggedInUserId != undefined && ctx.data.switchToFullScreen != undefined) {
						if(loggedInUser['id'] != ctx.data.loggedInUserId && ctx.data.switchToFullScreen) {
							setIsScreenShared(false)
							excecuteJisticommand('toggleShareScreen', '')
						}
					}
				}
			}
			if (userRole == 'STUDENT') {
				if (ctx.data.isAsset == true) {
					if (ctx.data.selectedAssetType == 'PDF' || ctx.data.selectedAssetType == 'VIDEO') {
						setLoadVideo(false)
						setLoadVideo(true)
						setChapterKey(ctx.data.chapterKey)
						setLectureKey(ctx.data.lectureKey)
						setSelectedAsset(ctx.data.selectedAssetId)
						
						// setPresentationType('ASSET')
						if (ctx.data.selectedAssetUrl != null) {
							
							if (ctx.data.lectureKey != ctx.data.prevLectureKey) {
								if (document.getElementsByClassName(`lectureIndex-${ctx.data.lectureKey}`)[0] && document.getElementsByClassName(`lectureIndex-${ctx.data.lectureKey}`)[0].firstElementChild && document.getElementsByClassName(`lectureIndex-${ctx.data.lectureKey}`)[0].firstElementChild.firstElementChild) {
									document.getElementsByClassName(`lectureIndex-${ctx.data.lectureKey}`)[0].firstElementChild.firstElementChild.click()
								}
							}
							setPage(1)
							setUrl(ctx.data.selectedAssetUrl)

						}
						else if (ctx.data.selectedAssetPageIndex != null) {
							setPage(ctx.data.selectedAssetPageIndex)
						}
						
					}
					setPresentationType(ctx.data.selectedAssetType)
					// if (ctx.data.fullScreen == true) {
					// 	alert.success(`Please Switch to full screen.`)
					// 	if (ctx.data.selectedAssetType == 'PPT') {
					// 		goFullScreen("react-jitsi-container")
					// 	}
					// }
				}else if(ctx.data.switchToFullScreen != undefined){
					// if (ctx.data.jitsiClassStarted) {
					// 	alert.success('Video Conference has been started. Please Join')
					// } else {
					// 	alert.success('Video Conference has been Ended.')
					// 	setPresentationType(previousPresentationState)
					// }
					// setcanJoinVideoConference(ctx.data.jitsiClassStarted)
					// setChapterKey(ctx.data.chapterKey)
					// document.getElementsByClassName(`lectureIndex-${ctx.data.lectureKey}`)[0].firstElementChild.firstElementChild.click()
					// setLectureKey(ctx.data.lectureKey)
					// setSelectedAsset(ctx.data.selectedAssetId)
						if (ctx.data.switchToFullScreen == true) {
							setIsMaximized(true)
							// alert.success(`Presentation has been started. Please Switch to full screen.`)
						}else{
							setIsMaximized(false)
						}
				}else if(ctx.data.courseStatusUpdated == true){
					getCourseChapter()
					setSelectedTest(ctx.data.selectedLectureData.tests)
					setSelectedLecture(ctx.data.selectedLectureData)
				} else if (ctx.data.selectedLecture) {
					let postedLecture = ctx.data.selectedLecture
					setChapterKey(ctx.data.chapterKey)
					if (document.getElementsByClassName(`lectureIndex-${ctx.data.lectureKey}`)[0] && document.getElementsByClassName(`lectureIndex-${ctx.data.lectureKey}`)[0].firstElementChild && document.getElementsByClassName(`lectureIndex-${ctx.data.lectureKey}`)[0].firstElementChild) {
						document.getElementsByClassName(`lectureIndex-${ctx.data.lectureKey}`)[0].firstElementChild.click()
					}
					setLectureKey(ctx.data.lectureKey)
					setCourseTestId(postedLecture['course_test_id'])
					setSelectedLecture(postedLecture)
					setPresentationType(postedLecture.type)
					setPreviousPresentationState(postedLecture.type)
					setDefaultViewHeading(postedLecture.title)
					if (postedLecture.type == 'TEST') {
						setSelectedTest(postedLecture.tests)
					}
					if (postedLecture.type == 'QUIZ') {
						setSelectedQuiz(postedLecture.quiz)
					}
				}else if(ctx.data.videoPlayed != undefined){
					var vid = document.getElementById("videoPresentation");
					if (vid) {
						if (ctx.data.videoPlayed == true) {
							try {
								vid.play()
							} catch (error) {
								alert.error('Failed to play video')
							}
						}else{
							vid.pause()
						}
					}
				}
			}
		}).on("join", function (message) {

			var userData = JSON.parse(message.info.user)
			// var loggedInUser = JSON.parse(localStorage.getItem('user'))
			// if (loggedInUser.id == userData.Id) {
				// 	let reqObj = {
					// 		"user_id": userData.Id,
					// 		"class_id": classId
					// 		}
					// 	postActivity(`admin-api/user-activity/user-class-join`,reqObj)
					// }
					var messageText = `${userData.Name} joined the class`
					if (userRole == 'TEACHER'){
						getOnlineUsers()
					}
					// alert.success(messageText)
		}).on("leave", function (message) {
			var userData = JSON.parse(message.info.user)
			if (userRole == 'TEACHER'){
				// var messageText = `${userData.Name} left the meeting`
				console.error(`User left the class : Name : ${userData.Name} `,message)
				// alert.success(messageText)
				getOnlineUsers()
			}
		});
		setGlobalCentriSubscription(subs)

		var individualSubs = centrifuge.subscribe(`${channelName}_student_chat_notification_individual`,  (ctx) => {
			if(localStorage.getItem('user')) {

				var userData = JSON.parse(localStorage.getItem('user'))
				if(ctx.data.userId == userData.id){
					// console.log(`New Message in Subscrption on channel : ${channelName}_student_chat_notification_individual`)	
					setNewIndividualMessage(true)
					setNewMessage(true)
				}		
			}
		})
		var studentIndividualSubs = centrifuge.subscribe(`${channelName}_notification_student_individual`,  (ctx) => {
			var userData = JSON.parse(localStorage.getItem('user'))
			if(ctx.data.userId != userData.id){
			// console.log(`New Message in Subscrption on channel : ${channelName}_notification_student_individual`)
			setNewStudentMessage(true)
			setNewMessage(true)
			var newChat = newStudentChat
			if(!newChat.includes(ctx.data.userId)) {
				newChat.push(ctx.data.userId)
			}
			// Here will be Toast--------
			setNewStudentChat(newChat)
			checkNewStudentMessage(newChat,studentArr)
		  }			
		})
		var groupSubs = centrifuge.subscribe(`${channelName}_notification_group`,  (ctx) => {
			var userData = JSON.parse(localStorage.getItem('user'))
			if(ctx.data.userId != userData.id){
				// console.log(`New Message in Subscrption on channel : ${channelName}_notification_group`)			
				setNewGroupMessage(true)
				setNewMessage(true)
				
			}
		})
		centrifuge.connect();

		

	
		
	}
	const getSetTeacherChannelActivity = (channelName, meetReference) => {
		CommonService.httpGetService(`admin-api/channel-activity/${channelName}`).then(res => {
			let resData = res.data
			if (role == 'TEACHER' && resData.is_screen_shared == true) {
				setChapterKey(resData.running_chapter_id ? resData.running_chapter_id : 0)
				setLectureKey(resData.running_lecture_id ? resData.running_lecture_id : 0)
				setSelectedAsset(resData.running_asset_id ? resData.running_asset_id : 0)
				setPresentationType(resData.running_asset_type ? resData.running_asset_type : 'ASSET')
				shareCroppedScreenCordinates();
				excecuteJisticommand('toggleShareScreen', meetReference)
				setIsScreenShared(resData.is_screen_shared)
			}
		}, error => {
		})
	}

	const getSetStudentChannelActivity = (channelName) => {
		CommonService.httpGetService(`admin-api/channel-activity/${channelName}`).then(res => {
			let resData = res.data
			setChapterKey(resData.running_chapter_id ? resData.running_chapter_id : 0)
			setLectureKey(resData.running_lecture_id ? resData.running_lecture_id : 0)
			setSelectedAsset(resData.running_asset_id ? resData.running_asset_id : 0)
			setPresentationType(resData.running_asset_type ? resData.running_asset_type : 'ASSET')
			setIsScreenShared(resData.is_screen_shared)
			if (resData.screen_coordinates_json && resData.screen_coordinates_json['CROP_X'] != undefined) {

				setJistiScreenShareCoordinates(JSON.stringify(resData.screen_coordinates_json))
			}
			setIsMaximized(resData.is_screen_shared)
			setConnectToJitsi(true)
		}, error => {
			setConnectToJitsi(true)
		})
	}

	const postActivity = (url,data) => {
		CommonService.httpPostService(url,data).then(res => {
			
		}, error => {
		})
	}

	const setOnlineStatus = (studentMap) => {
		let onlineCount = 0
		for (const key in studentArr) {
			if (studentArr.hasOwnProperty(key)) {
				let student = studentArr[key];
				if (studentMap.has(student.id)) {
					onlineCount++
					student.login_status = 'online'
				}else{
					student.login_status = 'offline'
				}
			}
		}
		setShowStudentList(false)
		setStudentData(studentArr)
		setOnlineStudents(onlineCount)
		setShowStudentList(true)
	}

	const getOnlineUsers = () => {
		CommonService.httpGetService(`admin-api/user-activity/present-users-in-class/${classId}`).then(res => {
			let onlineStudentMap = new Map
			if (res.data.length > 0) {
				for (const key in res.data) {
					if (res.data.hasOwnProperty(key)) {
						const student = res.data[key];
						onlineStudentMap.set(student,'online')
					}
				}
				setOnlineStatus(onlineStudentMap)
			}else{
				setOnlineStatus(onlineStudentMap)
			}
		}, error => {
		})

	}

	const checkNewStudentMessage = (chatIdArr,studentArr, readStudentId = null) => {
		if(studentArr && chatIdArr){
			for (const key in studentArr) {
				if (studentArr.hasOwnProperty(key)) {
					let student = studentArr[key];
					if(!student['updated_message_time']) {
						student['updated_message_time'] = moment("2020-12-01 12:00:00").format('YYYY-MM-DD HH:mm:ss')
					}
					if(chatIdArr.length > 0){
						for (const userId of chatIdArr) {
							if(student.id == userId){
								student['has_new_message'] = true
								student['updated_message_time'] = moment().format('YYYY-MM-DD HH:mm:ss')
							}
						}
					}else if(readStudentId && readStudentId == student.id){
						student['has_new_message'] = false
					}
				}
			}
			studentArr.sort(function(a, b) {
				if(a["updated_message_time"] && b["updated_message_time"]) {
					return new Date(b["updated_message_time"]) - new Date(a["updated_message_time"]);
				}
			});
			setShowStudentList(false)
			setStudentData(studentArr)
			setShowStudentList(true)
		}
	}

	const handleRead = (studentId,studentArr) => {
		var newChat = newStudentChat
		if(newChat){
			newChat.splice(newChat.indexOf(studentId),1)
			if(newChat.length == 0){
				// setClassList(true)
				setNewStudentMessage(false)
				setNewMessage(false)
			}
			setNewStudentChat(newChat)
			checkNewStudentMessage(newChat,studentArr, studentId)
		}
	}
	
	const postToCentrifuge = (assetDataParam) => {
		if (role == 'TEACHER' || assetDataParam.isTeacher == true) {
			let assetUrl = null;
			let assetId = selectedAsset;
			let pageIndex = 1
			let selectedAssetType = 'PDF'
			if (assetDataParam != null) {
				if (assetDataParam['url']) {
					// if(url != assetDataParam['url']) {
						assetUrl =  assetDataParam['url']
					// }
					// else{
					// 	assetUrl = null;
					// }
				}
				if (assetDataParam.type) {
					selectedAssetType = assetDataParam.type
				}
				if (assetDataParam['id']) {
					assetId = assetDataParam['id']
				}
				if (assetDataParam['pageIndex']) {
					pageIndex = assetDataParam['pageIndex']
					setSelectedAssetPageIndex(pageIndex)
				}
			}
			let postObjData = {
				"chapterKey": chapterKey ? chapterKey : 0,
				"lectureKey": assetDataParam.lectureKey ? assetDataParam.lectureKey : 0,
				"prevLectureKey": assetDataParam.prevLectureKey ? assetDataParam.prevLectureKey : 0,
				"selectedAssetId": assetId,
				"selectedAssetUrl": assetUrl,
				"selectedAssetType": selectedAssetType,
				"selectedAssetPageIndex": pageIndex,
				"isAsset": true,
				"fullScreen": assetDataParam.fullScreen != undefined ? assetDataParam.fullScreen : false
			}
			if (assetDataParam['jitsiClassStarted'] != undefined) {
				postObjData = {
					jitsiClassStarted : assetDataParam['jitsiClassStarted']
				}
			}
			if (assetDataParam['switchToFullScreen'] != undefined) {
				var loggedInUser = JSON.parse(localStorage.getItem('user'))
				postObjData = {
					switchToFullScreen : assetDataParam['switchToFullScreen'],
					loggedInUserId : loggedInUser['id']
				}
				postChannelActivity(assetDataParam['switchToFullScreen'])
			}

			if (assetDataParam['courseStatusUpdated'] != undefined) {
				postObjData = {
					"chapterKey": chapterKey ? chapterKey : 0,
					"lectureKey": lectureKey ? lectureKey : 0,
					courseStatusUpdated : assetDataParam['courseStatusUpdated'],
					selectedLectureData : assetDataParam['selectedLectureData']
				}
			}

			if (assetDataParam['type'] == 'TEST' || assetDataParam['type'] == 'QUIZ') {
				postObjData = {
					"chapterKey": chapterKey ? chapterKey : 0,
					"lectureKey": assetDataParam.lectureKey ? assetDataParam.lectureKey : 0,
					"prevLectureKey": assetDataParam.prevLectureKey ? assetDataParam.prevLectureKey : 0,
					selectedLecture : assetDataParam
				}
			}

			if (assetDataParam['videoPlayed'] != undefined) {
				postObjData = {
					"videoPlayed":  assetDataParam['videoPlayed']
				}
				
			}

			if (assetDataParam['videoState'] != undefined) {
				postObjData = {
					"videoState":  assetDataParam['videoState']
				}
				
			}



			let postObj = {
				"method": "publish",
				"params": {
					"channel": channelName,
					"data": postObjData
				}
			}

			
			if(assetDataParam.isTeacher && assetDataParam.videoPlayed != undefined){
				// setLoadVideo(false)
			}else{
				setLoadVideo(false)
			}
			setLoadVideo(true)
			setTimeout(() => {
				videoElement = document.getElementById("videoPresentation");
				if (videoElement) {
					videoElement.onplay = function () {
						let reqObj = {
							isTeacher: true,
							videoPlayed: true
						}
						postToCentrifuge(reqObj)
					};
					videoElement.onpause = function () {
						let reqObj = {
							isTeacher: true,
							videoPlayed: false
						}
						postToCentrifuge(reqObj)
					};
				}
			}, 100);
			globalCentriSubscription.publish(postObj.params.data).then(function successHandler(res) {
				console.log(`PUBLISHED on channel ${channelName} : `,postObj.params.data)
			 }, function errorHandler(err) {
				console.log('failed publishing', err);
			});
			
			// CommonService.publishToCentrifuge(postObj,centrifugeApiToken).then(res => {
			// 	console.log(res)
			// }, error => {

			// })
		}
	}

	const postChannelActivity = (is_screen_shared) => {
		try {
			let divCordinates = document.getElementById("divPresentation");
			let screenHeight = window.screen.availHeight -  window.innerHeight;
			screenHeight = window.screen.availTop + screenHeight
			let reqObj = {
				"channel_name":channelName,
				"is_screen_shared": is_screen_shared ? is_screen_shared : false,
				"teacher_id": teacherId,
				"running_chapter_id": chapterKey ? chapterKey : 0,
				"running_lecture_id": lectureKey ? lectureKey : 0,
				"running_asset_id": selectedAsset ? selectedAsset : 0,
				"running_asset_type": presentationType ? presentationType : "ASSET",
				"screen_coordinates_json" : {
					"CROP_X" : divCordinates.getBoundingClientRect().left,
					"CROP_Y" : 150,
					"CROP_W" : divCordinates.getBoundingClientRect().width,
					"CROP_H" : divCordinates.getBoundingClientRect().height,
					"DEVICE_PIXEL_RATIO" : window.devicePixelRatio || 1
				} 
			}
			window.scrollTo(0,divCordinates.getBoundingClientRect().top);
			CommonService.httpPostService(`admin-api/channel-activity`,reqObj).then(res => {
				console.log(res)
			}, error => {

			})
		} catch (error) {
			
		}
	}

	const getTeachers = async () => {
		setLoading(true);
		CommonService.httpGetService(`admin-api/teacher?id=${teacherId}`)
			.then(data => {
				setLoading(false);
				setteacherData(data)

			}, error => {
				setLoading(false);
			})
	}

	const getCourseChapter = async () => {
		// setLoading(true);
		return new Promise(async (resolve, reject) => {
		CommonService.httpGetService(`admin-api/course_chapter/lecture?course_id=${courseId}&class_id=${classId}`)
			.then(data => {
				if (data && data.length > 0) {
					setchapterData(data)
					// setDefaultViewHeading(data[0].title)
					setLoading(false);
				}
				resolve(data)
			}, error => {
				// debugger;
				// alert.error(error)
				setLoading(false);
				resolve([])
			})
		})
	}

	const getCentrifugeApiToken = async () => {
		// setLoading(true);
		return new Promise(async (resolve, reject) => {
		CommonService.httpGetService(`admin-api/centrifuge_auth`)
			.then(data => {
				if (data.token) {
					resolve(data.token)
				}else{
					resolve('')
				}
			}, error => {
				resolve('')
			})
		})
	}

	const getStudents = () => {
		CommonService.httpGetService(`admin-api/class_student/student/selected?class_id=${classId}`)
			.then(data => {
				setLoading(false);
				// dispatch({ type: 'studentData', payload: data })
				
				if (studentArr.length > 0) {
					data = studentArr
				} else {
					studentArr = data
				}
				for (const key in data) {
					if (data.hasOwnProperty(key)) {
						const element = data[key];
						let shortName = getShortHandName(element.full_name)
						element.shortName = shortName
						element.profileColor = `#${Math.floor(Math.random()*16777215).toString(16)}`
					}
				}
				dispatchMessage({type : "STUDENT_ARRAY" , data : data})
				setStudentData(data)
				setShowStudentList(true)

			}, error => {
				setLoading(false);

			})
	}

	const getShortHandName = (fullName) =>{
		let shortName = ""
        if (fullName != undefined) {
			let nameArr = fullName.split(" ")
			if (nameArr.length == 1) {
				shortName = nameArr[0].substring(0, 2)
			} else if (nameArr.length > 1)
				for (const key in nameArr) {
					if (nameArr.hasOwnProperty(key)) {
						if (Number(key) <= 1) {
							const element = nameArr[key];
							shortName += element.substring(0, 1)
						}
					}
				}
		}
		return  shortName
    }
	  
	const zoomIn = () => {
		setScale(scale * 1.2 );
	}
	  
	const zoomOut = () => {
		setScale(scale / 1.2 );
	}

    const goFullScreen = (elementId) => {

		const elem = document.getElementById(elementId);
		if(elem != undefined) {
			if(elem.mozRequestFullScreen){
				elem.mozRequestFullScreen();
			}
			else if(elem.webkitRequestFullscreen){
				elem.webkitRequestFullscreen();
			}
			else if(elem.msRequestFullscreen){
				elem.msRequestFullscreen();
			}else if (elem.requestFullscreen) {
				elem.requestFullscreen();
			}
		}
	}
	
	const exitFullScreen = () => {

		if(document.exitFullscreen){
			document.exitFullscreen();
		}
		else if(document.mozCancelFullScreen){
			document.mozCancelFullScreen();
		}
		else if(document.webkitExitFullscreen){
			document.webkitExitFullscreen();
		}
		else if(document.msExitFullscreen){
			document.msExitFullscreen();
		}

	}
	// Jitsi Functions 
	const handleAPI = (JitsiMeetAPI) => {
		console.log('Jitsi Reference : ',JitsiMeetAPI)
		setJistsiRef(JitsiMeetAPI)
		JitsiMeetAPI.addEventListener('videoConferenceJoined', () => {
			setCallJoined(true)
			meetStatus(true)
			if (role == 'STUDENT') {
				JitsiMeetAPI.isVideoMuted().then(muted => {
					if (!muted) {
						JitsiMeetAPI.executeCommand('toggleVideo');
					}
				// setisVideoCallStarted(!muted)
			});
			}
			
			JitsiMeetAPI.isAudioMuted().then(muted => {
				setMicOn(!muted)
			});
			if (JitsiMeetAPI) {
				let meetRef = {
					ref : JitsiMeetAPI
				}
				getSetTeacherChannelActivity(channelName,meetRef)
				
			}
		})
		
		JitsiMeetAPI.addEventListener('videoConferenceLeft', () => {
			// meetStatus(false)
			setLeaveClass(true)
			showClassLeaveAlert()
			setMicOn(false)
			setisVideoCallStarted(false)
		})

		JitsiMeetAPI.addEventListener('participantJoined', function(data) {
			const { id, role } = data;
			// Check if the participant is sharing their screen
			if (role === 'moderator') {
				// Pin the participant who is sharing the screen
				JitsiMeetAPI.executeCommand('pinParticipant', id);
			}
		})
		JitsiMeetAPI.addEventListener('participantLeft', function(data) {
			// alert.success(`${data.displayName} left the meeting`)
		})

		JitsiMeetAPI.addEventListener('trackAdded', (track) => {
			if (track.isVideoTrack()) {
				const participantId = track.getParticipantId();
				JitsiMeetAPI.executeCommand('pinParticipant', null); 
				// Pin the screen sharer
				JitsiMeetAPI.executeCommand('pinParticipant', participantId);
			}
		});
		// Listen for when a track (video or screen share) is removed
		JitsiMeetAPI.addEventListener('trackRemoved', (track) => {
			if (track.isVideoTrack()) {
				// Unpin the participant
				JitsiMeetAPI.executeCommand('pinParticipant', null);  // Unpinning
			}
		});
		if (role == 'STUDENT') {
			JitsiMeetAPI.addEventListener('endpointTextMessageReceived', (event) => {
				const data = event.data
				const { senderInfo, eventData } = data;
				if (eventData && eventData.text === 'mute_all') {
				  setMicOn(false);
				}
			});
		}
		
		
		JitsiMeetAPI.addEventListener('audioMuteStatusChanged', function(muted) {
			setMicOn(!muted.muted)
		})
		JitsiMeetAPI.addEventListener('videoMuteStatusChanged', function(muted) {
			setisVideoCallStarted(!muted.muted)
		})
		
		JitsiMeetAPI.addEventListener('screenSharingStatusChanged', function(event) {
			if (role == 'TEACHER') {
				
				let reqObj = {
					switchToFullScreen : event.on == true ? true : false
				}
				postToCentrifuge(reqObj)
				setIsScreenShared(event.on)
				if(reqObj.switchToFullScreen == false){
					let postObj = {
						"method": "publish",
						"params": {
							"channel": 'jitsi-stop',
							"data": {
								"stop_stream" : true,
								"channel_name" : channelName,
								"published_time" : `${new Date()}`
							}
						}
					}
					CommonService.publishToCentrifuge(postObj,centrifugeApiToken).then(res => {
					}, error => {
		
					})
					
				}
			}
			if(role == 'STUDENT' && iframe){
				console.log('DEBUG__ SCREEN_SHARED_FROM_TEACHER')
			}
		})
		JitsiMeetAPI.executeCommand('subject', ' ');
	}

	const excecuteJisticommand = (command, args) => {
		if (jitsiRef) {
			jitsiRef.executeCommand(command, args? args : '')
			if(command === 'muteEveryone'){
				alert.success('Muted Everyone.')
				jitsiRef.executeCommand('sendEndpointTextMessage', '', 'mute_all');
			}
		}else if (args.ref) {
			args.ref.executeCommand(command)
		}
	}

	const meetStatus = (status) => {
		let reqObj = {
			jitsiClassStarted : status
		}
		postToCentrifuge(reqObj)
	}

	const handleSubmit = (testdate, testtime) => {
        setLoading(true);
        const form = document.getElementById("testModalForm");
        // const form = event.currentTarget;
        if (form.checkValidity() === false) {
            setValidated(true);
            event.preventDefault();
            event.stopPropagation();
            setLoading(false);
        }
        else {
			setLoading(false);
			var time  = `${testdate} ${testtime}`
            updateTestStatus(time,selectedLecture);
        }
       
	};

	const updateTestStatus = (time,lecture) => {
		try {
			let reqObj ={
				"class_id": classId,
				"course_id": courseId,
				"lecture_id": lecture.id,
				"progress_status": `${lecture.progress_status == 'Seen' && lecture.type != 'ASSET' ? "NotSeen" : "Seen"}`,
				"enabled_status": `${lecture.active_status == 'enabled' && lecture.type != 'ASSET' ? "disabled" : "enabled"}`,
				"test_enabled_till_timestamp": moment(time).format('YYYY-MM-DD HH:mm:ss')
			}
			CommonService.httpPutService(`admin-api/class/progress-status`,reqObj)
			.then(async (data) => {
				hideTestModal()
				let updatedData = await getCourseChapter()
				let selectedLecture = null
				for (const key in updatedData) {
					if (updatedData.hasOwnProperty(key)) {
						const course = updatedData[key];
						for (const courseKey in course.lectures) {
							if (course.lectures.hasOwnProperty(courseKey)) {
								const lectureD = course.lectures[courseKey];
								if(lectureD.type== 'TEST' && lecture.id == lectureD.id){
									selectedLecture = lectureD
								}
							}
						}
					}
				}
				let obj = {
					courseStatusUpdated : true,
					selectedLectureData : selectedLecture
				}
				postToCentrifuge(obj)

				setSelectedTest(selectedLecture.tests)
				setSelectedLecture(selectedLecture)
				setLoading(false);
			}, error => {
				hideTestModal()
				alert.error(error)
				setLoading(false);
			})
		} catch (error) {
			hideTestModal()
			setLoading(false);
			alert.error('Something went wrong')
		}
	}

	const inviteCoHost = (coHost) =>{
		setLoading(true)
		let reqObj = {
			"receiver_email": coHost.primary_email,
			"mail_tag": "inviteCoHost",
			"content": {
				"class_url" : location.href,
				"class_name" : Class,
				"co_host_name" : coHost.full_name,
				"teacher_name" : teacherData[0].first_name
			}
		}
		CommonService.httpPostService(`user/utils/send-email`,reqObj)
			.then(res => {
				alert.success('Invitation Sent')
				setLoading(false)
			}, error => {
				alert.error('Failed to invite selected co-host, Please try again.')
				setLoading(false);
			})
	}

	const shareCroppedScreenCordinates = () =>{
		let divCordinates = document.getElementById("divPresentation");
		let screenHeight = window.screen.availHeight -  window.innerHeight;
		screenHeight = window.screen.availTop + screenHeight
		if(divCordinates != null) {

			let postObj = {
				"method": "publish",
				"params": {
					"channel": channelName,
					"data": {
						CROP_X : divCordinates.getBoundingClientRect().left,
						CROP_Y : divCordinates.getBoundingClientRect().top+screenHeight,
						CROP_W : divCordinates.getBoundingClientRect().width,
						CROP_H : divCordinates.getBoundingClientRect().height,
						DEVICE_PIXEL_RATIO : window.devicePixelRatio || 1,
						channel_name : channelName
					}
				}
			}
			window.scrollTo(0,divCordinates.getBoundingClientRect().top);
			// alert.success(`coordinates ${divCordinates.getBoundingClientRect().left} | ${divCordinates.getBoundingClientRect().top+screenHeight} | ${divCordinates.getBoundingClientRect().width} | ${divCordinates.getBoundingClientRect().height}` )
			console.log('Shared Screen CROP_X : ',divCordinates.getBoundingClientRect().left)
			console.log('Shared Screen CROP_Y : ',divCordinates.getBoundingClientRect().top+screenHeight)
			console.log('Shared Screen CROP_W : ',divCordinates.getBoundingClientRect().width)
			console.log('Shared Screen CROP_H : ',divCordinates.getBoundingClientRect().height)
			console.log('Centrifuge Post Obj for share coprdinates : ',postObj)
			CommonService.publishToCentrifuge(postObj,centrifugeApiToken).then(res => {
			}, error => {

			})
			
			
		}
	}

	const getQuestions = () => {
		CommonService.httpGetService(`admin-api/security_questions/user_questions?user_id=${userId}`)
		.then(data => {
		  if(data.data.length > 0) {
			setQuestions(data.data)
			setError(false)
		  }
		}, error => {
		  console.log(error)
		  // alert.error(error)
		})
	  }
	  
	  function getTimeSplits(time,partsCount=10) {
		let i = partsCount;
		  let numArray = [];
		  while(i <= partsCount){
		  while(time > 0 && numArray.length<10) {
			  let randomNum = Math.round(Math.random() * time) + 1;
			  numArray.push(randomNum);
			  time -= randomNum;
		  }
		  i += 1;
		  }
		  return numArray
	  }

	  
	  useEffect(() => {
		let start = ""+moment().format('YYYY-MM-DD ')+ classStartTime;
		let end = ""+moment().format('YYYY-MM-DD ')+ classEndtime;
		if(moment(end).isBefore(moment(start))){
			end = moment(end).add(1,'d');
		}
		let diff = moment(end).diff(moment(start),'milliseconds')
		let splits = getTimeSplits(diff,10)
		setTimeSplits(splits)
	  }, [classStartTime, classEndtime])


	//   function randomNoRepeats(array) {
	// 	array = array.slice(0);
	// 	return function() {
	// 	  if (copy.length < 1) { copy = array.slice(0); }
	// 	  let index = Math.floor(Math.random() * copy.length);
	// 	  let item = copy[index];
	// 	  copy.splice(index,  1);
	// 	  array = copy
	// 	  return item;
	// 	};
	//   }
    
	let timeForFirstTimeout = 30000
	let timeForSecondTimeout = timeForFirstTimeout*2 
	let timeForThirdTimeout = timeForFirstTimeout*3
	let timeForFourthTimeout = timeForFirstTimeout*4
	let timeForFifthTimeout = timeForFirstTimeout * 5
	let timeForSixthTimeout = timeForFirstTimeout * 6
	let timeForSeventhTimeout = timeForFirstTimeout * 7
	let timeForEightTimeout = timeForFirstTimeout * 8
	let timeForNinthTimeout = timeForFirstTimeout * 9
	let timeForTenthTimeout = timeForFirstTimeout * 10
    //  let timeForFirstTimeout = timeSplits[0]
	//  if(timeForFirstTimeout > 300000) {
	// 	 timeForFirstTimeout = 300000
	//  }
	  useEffect(() => {
		if(questions && questions.length > 0) {
		
		function randomNoRepeats(array) {
			console.log(array)
			let ind = array.splice(Math.floor(Math.random() * array.length),1)
			console.log('CHOOSEN_QUES : ',ind)
			return ind
		
		}
		let questionsCopy = JSON.parse(JSON.stringify(questions))
		let startTimeOut = 3600000
		for (let i = 0; i < questions.length; i++) {
			let quest = randomNoRepeats(questionsCopy)
			let timeout = startTimeOut *(i+1)
			setTimeout(() => {
				console.log(`QUESTION_IN_TIMEOUT : ${i} with timeout ${timeout} :`,quest)
				setQuestion(quest[0])
				setShowQAModal(true)
			},timeout,quest,i);
		}
		}
	  }, [questions])

	return (
		<div>
		
		<LoadingOverlay
		active={loading}
		spinner
		text='Loading...'
		className="loaderStyle"
		>
		{
			showQAModal && role == "STUDENT" && presentationType != 'TEST' &&
			<SessionQA userId={userId} showModal={showQAModal} question={question} closeModal={()=>setShowQAModal(false)} />
			// : 
			// <div className="remaining-attempts">
			// <p className="mb-0"><span className="text-bold mr-3">ALERT:</span> You have only 2 attemps remained to answer questions.</p>
			// </div>
		}
		{/* <Beforeunload onBeforeunload={() => "Leaving ?"} /> */}
			{
				isLoggedIn && (presentationType != 'TEST') ?	<Prompt
				when={isBlocking}
				message={location =>
				  `Are you sure you want to leave?`
				}
			  /> : ''
			}
			<div className="containerBox overflowHidden">
				<Header></Header>
					{
						browserWarning ? <div className="alert alert-warning alert-dismissible fade show p-0 text-body text-center w-100" role="alert">
						{broswerWarningMessage}
								  <button type="button" onClick={()=> setBrowserWarning(false)} className="close p-0 mr-5" data-dismiss="alert" aria-label="Close">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
					: null
					}
				<div className="contentBox">
					<div className={'videocallSection rel col-md-2 col-sm-12 p-0 m-0'}>
						<div className="cardBody position-relative">						 
						{/* <span className="videoReloadBtn">Reload</span> */}
							{
								role == 'TEACHER' && channelName && userData && enableVideoCall  ? (
									// <Teacher video={isVideoCamera} roomName={channelName} /> 
									<>
									<TeacherKurento video={isVideoCamera} roomName={channelName} userData={userData}/>
									</>
								) : ''
							}
							{
								role == 'STUDENT' && teacherData && channelName && userData ? <>
								{/* <Student  teacherData={teacherData}  roomName={channelName} /> */}
								<StudentKurento  teacherData={teacherData} enableVideoCall={enableVideoCall}  roomName={channelName} userData={userData} />
								<div className="videoIconsBox text-center" >
									<i className="ml-3 cursor-pointer videoIcon" style={{ width: "25px" }} title="Mute" onClick={() => excecuteJisticommand('toggleAudio', '')}>
										<img className="img-fluid"  style={{ width: "25px" }} src={isMicOn ? micOn : micOff} />
									</i>
									<i className="ml-3 cursor-pointer videoIcon" style={{ width: "25px" }} title="End Class" onClick={() => excecuteJisticommand('hangup', '')}>
										<img className="img-fluid"  style={{ width: "25px" }} src={videoCallStarted} />
									</i>
								</div>
							</> : ''
							}
							
							<div className={` text-center ct-area mt-2 py-4 ${role == 'STUDENT' ? 'd-none' : ''}`}>
								{
									role == 'TEACHER' ?
										<>
										<h5 className={`${isScreenShared == true ? 'd-block' : 'd-none'}`}>
											You have shared your screen.
										</h5>
										<h5 className={`${isCallJoined == true ? 'd-none' : 'd-block'}`}>
											Starting Meeting....
										</h5>

											<div className={`d-flex justify-content-center ${!isCallJoined ? 'disableBlock' : ''}`} >
												<i className="cursor-pointer" style={{ width: "35px" }} title="Share Screen" onClick={
													(e) => {
														shareCroppedScreenCordinates();
														excecuteJisticommand('toggleShareScreen', '')
													}}>
													<img className="img-fluid" src={isScreenShared == true ? shareScreenOn : shareScreenOff} />
												</i>
												{
													enableVideoCall && <i className=" ml-3 cursor-pointer" style={{ width: "35px" }} title="Toggle Video" 
													onClick={() => {
														let videoState = !isVideoCamera;
														if (videoState) {
															let reqObj = {
																role : role,
																videoState : videoState
															}
															postToCentrifuge(reqObj)
														}
														setTimeout(() => {
															setIsVideoCamera(videoState)
														}, 1000);
													}}
												>
													<img className="img-fluid" src={isVideoCamera == true ? videoCallOn : videoCallOff} />
												</i>
												}
												<i className="ml-3 cursor-pointer" style={{ width: "35px" }} title="Mute" onClick={() => excecuteJisticommand('toggleAudio', '')}>
													<img className="img-fluid" src={isMicOn ? micOn : micOff} />
												</i>
												<i className="ml-3 cursor-pointer" style={{ width: "35px" }} title="End Class" onClick={() => excecuteJisticommand('hangup', '')}>
													<img className="img-fluid" src={videoCallStarted} />
												</i>
												<i className="ml-3 cursor-pointer" style={{ width: "35px" }} title="Mute Everyone" onClick={() => excecuteJisticommand('muteEveryone')}>
													<img className="img-fluid" src={muteEveryone} />
												</i>
												{/* <i className="ml-3 cursor-pointer" style={{ width: "35px" }} title="More" >
													<Dropdown className="d-flex align-items-center">
														<Dropdown.Toggle variant="" id="dropdown-basic" className="p-0">
															<img className="img-fluid" src={moreOption} />
														</Dropdown.Toggle>
														<Dropdown.Menu className="read-status">
															<Dropdown.Item onClick={() => {
																excecuteJisticommand('muteEveryone')
															}
															}><span>Mute Everyone</span></Dropdown.Item>
														</Dropdown.Menu>
													</Dropdown>

												</i> */}
											</div>
										</>
							
							: ""
					}
							</div>
							</div>
							
							<hr className="badge-dark my-2"/>							
							<div className={showChatBox ? "chattingAreaS " : " mobilechattingAreaS" }>
							<span className="chatBtn d-md-none d-lg-none d-block btn text-white" onClick={()=>setShowChatBox(!showChatBox)}>
							{showChatBox == true ? <i className="fas fa-eye-slash"></i> : <i className="fas fa-eye"></i>}	{showChatBox == true ? 'Hide' : 'Show'} Chat  {
								newMessage == true ? <i aria-hidden="true" className=" ml-3 fa fa-circle fontThirteen text-danger"></i> : ''
							}
							</span>
							<div className={`${showChatBox == true ? 'd-block' : 'd-none'} messagesBox  d-md-block d-lg-block`}>
							<div className="d-flex">
							{
								role != 'STUDENT' ?	<span className="list-tag" style={{backgroundColor: (classList == true ? "#40c6f7" : "#c6c3c3")}} onClick={() => {
									setClassList(true)
									setNewStudentMessage(false)
									const inputBox = document.getElementById('textInputBox');
									if (inputBox) {
										inputBox.focus()
									}
									setNewMessage(false)
								}}>{
								 newStudentMessage ?  <i className="fa fa-comment new-message mr-2"></i> : ''
							}
									My Chat  ({onlineStudents ? onlineStudents : 0}/{studentData ? studentData.length : 0 }) </span> : (
									<span className="list-tag" style={{backgroundColor: (classList == true ? "#40c6f7" : "#c6c3c3")}} onClick={ async () =>  {
										await getIndividualMessages();
										setClassList(true)
										setNewIndividualMessage(false)
										setNewMessage(false)
										const inputBox = document.getElementById('textInputBox');
									if (inputBox) {
										inputBox.focus()
									}
									}}>
									{
								 newIndividualMessage ?  <i className="fa fa-comment new-message mr-2"></i> : ''
							}
									My Chat 
									
									</span>
								)
							}
							
							<span className="chat-tag"
							style={
								{backgroundColor: (classList == true ? "#c6c3c3" : "#40c6f7")}
							}
							 onClick={
								async ()  => {
									console.log("step -1")
									await getGroupMessages();
									
									setClassList(false)
										setNewMessage(false)
										setNewGroupMessage(false)
									const inputBox = document.getElementById('textInputBox');
									if (inputBox) {
										inputBox.focus()
									}
								}
							}> 
							{
								newGroupMessage ?  (
									<i className="fa fa-comment new-message mr-2"></i>
								) : ''
							}Group Chat  
							</span>
							</div>
							
							{
								classList == true ? (
									<>
										{
											role == 'TEACHER' && showStudentList ? 
												<OngoinClassStudentsComponent classId={classId} studentData={studentData} channelName={`${channelName}_student_chat`} centrifugeToken={centrifugeApiToken} handleReadMark={handleRead}/> : (
												<>
													{
														showIndividualMessageComponent ? <MessageComponent classId={classId}  groupMessage={individualMessageArr} channelName={`${channelName}_student_chat_${userData.id}`} typeOfChat='student_individual' handleNewMessage={handleMessage} centrifugeToken={centrifugeApiToken}/> : ''
													}
												</>
											)
										}
									</>
								) : (
										showMessageComponent ? <MessageComponent classId={classId} groupMessage={groupMessageArr} channelName={`${channelName}_groupChat`} typeOfChat='group' handleNewMessage={handleMessage} centrifugeToken={centrifugeApiToken}/> : ''
									)
							}
							</div>
							</div>
							</div>
					<div id="divPresentation" className={`presentationSection  col-md-8 col-sm-12 padding-none margin-none ${role == 'STUDENT' && isMaximized  ? 'isPPT' : ''}`} >
						<div className={`videoCallLive ${role != 'STUDENT' ? 'd-none' : ''}  ${isMaximized == true ? 'showFullScreen' : 'showFullScreen d-none'}`}>

							<div id={`jisti-container ${isMaximized == true ? 'showFullScreen' : ''}`} className={`jisti-container ${isMaximized == true ? 'showFullScreen' : ''}`} >
								{
									(role == 'TEACHER' || connectToJitsi) && displayName && role && !isClassLeaved ? <Jitsi
									roomName={channelName}
									displayName={displayName ? displayName : 'User'}
									// domain={`m2.flsecurityschool.com?cordinates=${jistiScreenShareCoordinates}&channel_name=${channelName}&role=${role}`}
									domain={`${jitsiDomain}?cordinates=${jistiScreenShareCoordinates}&channel_name=${channelName}&role=${role}`}
									jwt={jistiJwtToken}
									disableDeepLinking={true}
									config={{ enableClosePage: false,
										startAudioOnly: false, 
										disableDeepLinking: true,
										startWithVideoMuted: true,
										startWithAudioMuted: role == 'TEACHER' ? false : true,
										startVideoMuted: true,
										constraints: {
											video: {
												mediaSource: 'screen',
												width: { max: 1920 },
												height: { max: 1080 },
												// Cropping options: Set the coordinates of the area to crop
												crop: {
													x: 100,  // Start X coordinate (left)
													y: 50,   // Start Y coordinate (top)
													width: 800,   // Width of the area to crop
													height: 600   // Height of the area to crop
												}
											} 
										} 
										
									}}
									interfaceConfig={{
										DISABLE_JOIN_LEAVE_NOTIFICATIONS : true,
										DISABLE_FOCUS_INDICATOR : true,
										DISABLE_PRESENCE_STATUS : true,
										SHOW_PROMOTIONAL_CLOSE_PAGE : true,
										FILM_STRIP_MAX_HEIGHT : 1,
										SETTINGS_SECTIONS : [''],
										VIDEO_QUALITY_LABEL_DISABLED : true,
										CONNECTION_INDICATOR_DISABLED : true,
										DISABLE_DOMINANT_SPEAKER_INDICATOR : true,
										DISABLE_RINGING: true,
										SHOW_JITSI_WATERMARK:false,

									}}
									configOverwrite={{ 
										defaultLanguage: 'en',
										constraints: {
											video: {
												mediaSource: 'screen',
												width: { max: 1920 },
												height: { max: 1080 },
												// Cropping options: Set the coordinates of the area to crop
												crop: {
													x: 100,  // Start X coordinate (left)
													y: 50,   // Start Y coordinate (top)
													width: 800,   // Width of the area to crop
													height: 600   // Height of the area to crop
												}
											} 
										} ,
										prejoinConfig : {
											enabled: false
										}
									}}
									containerStyle={{ width: '100%', height: '100%' }}
									loadingComponent={Loader}
									onAPILoad={handleAPI}
								/> : ''
								}	
							</div>
							</div>
								<div
									className={
										`${url ? 'screenView' : 'screenView  align-items-center justify-content-around'} ${isMaximized == true ? 'd-none' : 'd-flex'}`
									}
									style={{position:'relative'}}
									id="temp"
									>

									{
										(presentationType == 'ASSET' || presentationType == 'PDF' ) && url && url != "" ? (
											
											
											<> 

												<PdfComponent
													// key={url}
													src={{
														url: url ? url : ''
													}}
													page_number = {page}
													role={role}
													onChangePageNum={handleChange}
													scale={scale}
													
												/>
											
												<div className="nav-row"  style={{position:'absolute', bottom: -2}} >
													<span className={`zoom-icon ${role == 'STUDENT' ? `disableBlock` : ''}`}
														><i className="fas fa-search-minus ml-2" title="zoom out" onClick={() => zoomOut()}></i> <i className="fas fa-search-plus ml-2" title="zoom in" onClick={() => zoomIn()}></i></span>
													<div>
													<button type="button" 
													title="Previous"
														onClick={(e) => {
															let pageIndex = page -1;
															if(pageIndex < 1) {
																pageIndex = 1;
															}
															setPage(pageIndex);
															let obj = {
																pageIndex: pageIndex
															}
															postToCentrifuge(obj)
														}} 
														className="previous"> <i className="fas fa-chevron-left "> </i>
													</button>
													{ page <= pageNum ?(
														<span className="pageNumber">Page No. {page} / {pageNum}</span>
													): <span className="pageNumber">Page No. {pageNum} / {pageNum}</span>
													}
													<button type="button" 
														title="Next"
														onClick={() =>  {
															let pageIndex = page +1;
															setPage(page=>page+1 )
															let obj = {
																pageIndex: pageIndex
															}
															postToCentrifuge(obj)
														}} className="next">  <i className="fas fa-chevron-right "></i> 
													</button>
													</div>
													<span className="fullsc-icon"><i className="fas fa-compress mr-2" title="Exit full screen" onClick={() => exitFullScreen()}></i> <i className="fas fa-expand mr-2" title="Full screen" onClick={() => goFullScreen("temp")}></i></span>
												</div>
											</>

										) : 
										(
											presentationType == 'TEST' ? 
											<TestComponent courseId={courseId} classId={classId} testId={courseTestId} selectedTest={selectedTest} selectedLecture = {selectedLecture}/> 
											: (
												presentationType == 'QUIZ' ? 
												<QuizComponent courseId={courseId} classId={classId} testId={courseTestId} selectedQuiz={selectedQuiz} selectedLecture = {selectedLecture}/>
												: (
													presentationType == 'PPT' && url ?
																<iframe id="pptScreenArea"
																	src={url}
																width = "100%" height="98%" frameBorder="0">
																</iframe>
													: (
														presentationType == 'VIDEO'  && url ?
														<>
														{
															role == 'STUDENT' ? 
															<>
														{
															loadVideo ? <video ref={videoRef} controls playsInline controlsList="nofullscreen nodownload" id="videoPresentation"  width = "100%" height="100%" 
															poster={videPlaceHolder}
															>
															<source src={url} type="video/mp4" />
															
														</video> : null
														}
														</> : <>
														{
															loadVideo ? <video ref={videoRef} id="videoPresentation" controls 
															disablePictureInPicture controlsList="nofullscreen nodownload" width = "100%" height="100%">
															<source src={url} type="video/mp4" />
															
														</video> : null
														}
														</>
														}
														</>
														: (
															<span className="fa-2x font-weight-bold bfrVideoHeading">{defaultViewHeading}</span>
															)
													
													)
												
												)
											
											)
										
										)
										
											
									}

								</div>
						</div>
					<div 
						className={`${role == 'STUDENT' ? ` courseDetails` : 'courseDetails'}`}>
							{/* add disableBlock to disable this section for student */}
							<div className="courseHeading">{Class}</div>
						<div className="lectureList">
							
							<Accordion preExpanded={[chapterKey]} allowZeroExpanded>
								{chapterData && chapterData.map((chapterDta, index) => (
									<AccordionItem key={index} uuid={index}>
										<AccordionItemHeading onClick={() => {
											setDefaultViewHeading(chapterDta.title)
										}} >
											<AccordionItemButton>
												{index + 1}: {chapterDta.title}
											</AccordionItemButton>
										</AccordionItemHeading>
										<AccordionItemPanel>
											<Accordion preExpanded={[lectureKey]} allowZeroExpanded>
												{chapterDta.lectures && chapterDta.lectures.map((lectureData, lectureIndex) => (
													<AccordionItem key={lectureIndex} uuid={lectureIndex}
													className={`lectureIndex-${lectureIndex}`} style={{ boxShadow: "none" , padding: "0px", marginBottom: "0px"}} >
														{
														lectureData.type == 'ASSET'	? (
														<AccordionItemHeading 
														
														className={`lectureHeader d-flex 
														`} style={{ backgroundColor: "#fff", borderBottom: "1px solid #ededed" }}  >
															<AccordionItemButton  className="accordion__button accordion_button"
															>
																<span className="lectureName accordion_panel p-0"
																onClick={() => {
																	setChapterKey(index)
																	setLectureKey(lectureIndex)
																	setPreviousLectureKey(lectureKey)
																	setCourseTestId(lectureData['course_test_id'])
																	setSelectedLecture(lectureData)
																	setPresentationType(lectureData.type)
																	setPreviousPresentationState(lectureData.type)
																	setDefaultViewHeading(lectureData.title)
																	lectureData['lectureKey'] = lectureIndex
																	lectureData['prevLectureKey'] = lectureKey
																	if (lectureData.type == 'TEST') {
																		setSelectedTest(lectureData.tests)
																		postToCentrifuge(lectureData)
																	}
																	if (lectureData.type == 'QUIZ') {
																		setSelectedQuiz(lectureData.quiz)
																		postToCentrifuge(lectureData)
																	}

																}}
																> 
																
																{
																	lectureData.type == 'ASSET'  && lectureData.progress_status == 'Seen'  ? (
																		<i className="fas fa-check-circle mr-2" style={{color: "#A5C75F", marginTop: "4px" }}> </i>
																	) : ''
																}
																{lectureData.title} 
																{
																	role == 'STUDENT' && lectureData.type == 'TEST' && lectureData.active_status == 'enabled' && lectureData.test_enabled_till_timestamp  ? (
																		<> &nbsp;&nbsp;&nbsp;	<Countdown date={new Date(lectureData.test_enabled_till_timestamp.split(" ").join("T"))} daysInHours={true}/></>
																	) : ''
																}

{
																role != 'STUDENT' && lectureData.type != "QUIZ" ? (
																	<Dropdown>
																		<Dropdown.Toggle variant="" id={`dropdown-basic-${lectureIndex}`}>
																			<div>
																				<span> 
																					<i className="fas fa-ellipsis-v "></i>
																				</span>
																			</div>
																			
																		</Dropdown.Toggle>
																		<Dropdown.Menu className="read-status">
																			{
																				lectureData.type == 'ASSET' ? (
																					<Dropdown.Item 
																					onClick={() => 
																						{
																							setSelectedLecture(lectureData)
																							setLoading(true)
																							setTimeout(() => {
																								updateTestStatus(new Date,lectureData)
																							}, 200);
																						}
																					}
																					>
																						<span>Mark as completed</span>
																				</Dropdown.Item>
																				) : ""
																			}

																			{
																				lectureData.type == 'TEST' ? (
																					<Dropdown.Item  onClick={() => 
																						{
																							setSelectedLecture(lectureData)
																							if (lectureData.active_status != 'enabled') {
																								showTestModal()
																							}else{
																								setLoading(true)
																								setTimeout(() => {
																									updateTestStatus(new Date,lectureData)
																								}, 200);
																							}
																						}
																					} >
																						<span>
																							<>
																							{lectureData.active_status == 'enabled' ? "Disable" : "Enable"}
																							</>	Test</span>
																				</Dropdown.Item>
																				) : ""
																			}
																	</Dropdown.Menu>
																</Dropdown>
																) : ''
															}
																
																</span>
															</AccordionItemButton>
															
														</AccordionItemHeading>
															) : ''
														}
														{
														lectureData.type != 'ASSET'	? (
															<span className="accordion_panel lectureName"
															
															onClick={() => {
																setChapterKey(index)
																setLectureKey(lectureIndex)
																setPreviousLectureKey(lectureKey)
																setCourseTestId(lectureData['course_test_id'])
																setSelectedLecture(lectureData)
																setPresentationType(lectureData.type)
																setPreviousPresentationState(lectureData.type)
																setDefaultViewHeading(lectureData.title)
																lectureData['lectureKey'] = lectureIndex
																lectureData['prevLectureKey'] = lectureKey
																if (lectureData.type == 'TEST') {
																	setSelectedTest(lectureData.tests)
																	postToCentrifuge(lectureData)
																}
																if (lectureData.type == 'QUIZ') {
																	setSelectedQuiz(lectureData.quiz)
																	postToCentrifuge(lectureData)
																}
																if(isScreenShared && role == 'TEACHER') {
																	// shareCroppedScreenCordinates();
																	excecuteJisticommand('toggleShareScreen', '')
																}
															}}> 
																
																{
																	lectureData.type == 'ASSET'  && lectureData.progress_status == 'Seen'  ? (
																		<i className="fas fa-check-circle mr-2" style={{color: "#A5C75F", marginTop: "4px" }}> </i>
																	) : ''
																}
																{lectureData.title} 
																{
																	role == 'STUDENT' && lectureData.type == 'TEST' && lectureData.active_status == 'enabled' && lectureData.test_enabled_till_timestamp  ? (
																		<> &nbsp;&nbsp;&nbsp;	<Countdown date={new Date(lectureData.test_enabled_till_timestamp.split(" ").join("T"))} daysInHours={true}/></>
																	) : ''
																}

{
																role != 'STUDENT' && lectureData.type != "QUIZ" ? (
																	<Dropdown>
																		<Dropdown.Toggle variant="" id={`dropdown-basic-${lectureIndex}`}>
																			<div>
																				<span> 
																					<i className="fas fa-ellipsis-v "></i>
																				</span>
																			</div>
																			
																		</Dropdown.Toggle>
																		<Dropdown.Menu className="read-status">
																			{
																				lectureData.type == 'ASSET' ? (
																					<Dropdown.Item 
																					onClick={() => 
																						{
																							setSelectedLecture(lectureData)
																							setLoading(true)
																							setTimeout(() => {
																								updateTestStatus(new Date,lectureData)
																							}, 200);
																						}
																					}
																					>
																						<span>Mark as completed</span>
																				</Dropdown.Item>
																				) : ""
																			}

																			{
																				lectureData.type == 'TEST' ? (
																					<Dropdown.Item  onClick={() => 
																						{
																							setSelectedLecture(lectureData)
																							if (lectureData.active_status != 'enabled') {
																								showTestModal()
																							}else{
																								setLoading(true)
																								setTimeout(() => {
																									updateTestStatus(new Date,lectureData)
																								}, 200);
																							}
																						}
																					} >
																						<span>
																							<>
																							{lectureData.active_status == 'enabled' ? "Disable" : "Enable"}
																							</>	Test</span>
																				</Dropdown.Item>
																				) : ""
																			}
																	</Dropdown.Menu>
																</Dropdown>
																) : ''
															}
																
																</span>
														) : ''	
														}
														{
															lectureData.assets && lectureData.assets.length > 0 ? (
																<AccordionItemPanel>
														<div style={{ padding: 0 }} >
																{/* <span className="lectureDetail font-weight-bold border-bottom border-danger">Assets</span> */}

																{/* Assets Accordion */}
																{lectureData.assets.map((assetData, assetIndex) => (
																	<div key={assetIndex} className={`border-bottom  ${role == 'STUDENT' ? 'disableBlock' : ''}`} onClick={() => {
																		setPresentationType(assetData.type)
																		setPreviousLectureKey(lectureKey)
																		setPreviousPresentationState(lectureData.type)
																		setSelectedAsset(assetData['id']);
																		assetData['lectureKey'] = lectureIndex
																		assetData['prevLectureKey'] = lectureKey
																		if (['PDF', 'VIDEO', 'TEST', 'QUIZ'].includes(assetData.type)) {
																			setUrl(assetData['url']);
																			assetData['pageIndex'] = 1
																			assetData['isAsset'] = true
																			setPage(1);
																			assetData['fullScreen'] = false
																			
																			setTimeout(() => {
																				postToCentrifuge(assetData)
																			}, 2000);
																			if(isScreenShared) {
																				// shareCroppedScreenCordinates();
																				excecuteJisticommand('toggleShareScreen', '')
																			}
																		}
																		if (assetData.type == 'PPT') {
																			assetData['chIndex'] = index
																			assetData['lectIndex'] = lectureIndex
																			assetData['asstIndex'] = assetIndex
																			checkForUrl(assetData)

																			if(!isScreenShared) {
																				shareCroppedScreenCordinates();
																				excecuteJisticommand('toggleShareScreen', '')
																			}
																			// setUrl(assetData['one_drive_embed_url']);
																			// postToCentrifuge(assetData)
																		}
																	}}>
																		{
																			selectedAsset && selectedAsset == assetData.id ? (
																			<span className="assetdetail selected-asset align-items-baseline">
																				<>
																				{
																					assetData.type == 'PDF' ? <i className="fas mr-1 fa-file-pdf pdf-color"></i> : ''
																				}
																				{
																					assetData.type == 'PPT' ? <i className="fas mr-1 fa-file-powerpoint ppt-color"></i> : ''
																				}
																				{
																					assetData.type == 'VIDEO' ? <i className="fas fa mr-1 fa-file-video-o video-color"></i> : ''
																				}
																				</>
																				 {assetData.title}</span>
																			) : (
																			<span className="assetdetail align-items-baseline">
																				<>
																				{
																					assetData.type == 'PDF' ? <i className="fas mr-1 fa-file-pdf pdf-color"></i> : ''
																				}
																				{
																					assetData.type == 'PPT' ? <i className="fas mr-1 fa-file-powerpoint ppt-color"></i> : ''
																				}
																				{
																					assetData.type == 'VIDEO' ? <i className="fas fa mr-1 fa-file-video-o video-color"></i> : ''
																				}
																				</>
																				 {assetData.title} </span>
																				)
																		}
																		<span className="lectureDetail"></span>
																	</div>
																))
																}

															</div>
														</AccordionItemPanel>
															) : ''
														}
													</AccordionItem>
												))}
											</Accordion>
										</AccordionItemPanel>
									</AccordionItem>
								))}
							</Accordion>


						</div>

					</div>
				</div>
			</div>

			{/* Test Modal */}
			<Modal show={testModalShow}
				onHide={hideTestModal}
				backdrop="static"
				keyboard={false}>
				<Modal.Header closeButton>
					<Modal.Title>{selectedLecture.title}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form  id="testModalForm" noValidate validated={validated}>
						<Form.Row>
							<Form.Group as={Col} controlId="formGridDate">
								<Form.Label>Enable Test Till Date*</Form.Label>
								<Form.Control type="date" value={enableTestTillDate} onChange={e => setEnableTestDate(e.target.value)} required />
								<Form.Control.Feedback type="invalid">
									Please Choose Date.
                        </Form.Control.Feedback>
							</Form.Group>

							<Form.Group as={Col} controlId="formGridTime">
								<Form.Label>Enable Test Till Time*</Form.Label>
								<Form.Control type="time" value={enableTestTillTime} onChange={e => setEnableTestTime(e.target.value)} required />
								<Form.Control.Feedback type="invalid">
									Please Choose Time.
                        </Form.Control.Feedback>
							</Form.Group>

						</Form.Row>
					</Form>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={hideTestModal}>
						Cancel
                            </Button>
					<Button variant="primary" onClick={() => handleSubmit(enableTestTillDate,enableTestTillTime)}> Enable</Button>
				</Modal.Footer>
			</Modal>

			{/* Invite Co-Host Modal */}
			<Modal show={classLeaveAlert}
				onHide={hideClassLeaveModal}
				backdrop="static"
				keyboard={false}>
				<Modal.Header closeButton>
					<Modal.Title>Alert</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					Are you sure to leave the class
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={()=>{
						hideClassLeaveModal()
						setLeaveClass(true)
						setCallJoined(false)
						setTimeout(() => {
							setLeaveClass(false)
						}, 1000);
					}}>
						Cancel
                            </Button>
					<Button variant="primary" onClick={() => {
						setLeaveClass(true)
						setIsBlocking(false)
						setTimeout(() => {
							location.href = `${location.origin}/courses`
						}, 1000);
					}}> Yes</Button>
				</Modal.Footer>
			</Modal>

			{/* Welcome Modal */}
			<Modal show={showWelcomeModal}
				onHide={hideWelcomModalFn}
				backdrop="static"
				keyboard={false}>
				<Modal.Header closeButton>
					<Modal.Title>Alert</Modal.Title>
				</Modal.Header>
				<Modal.Body>
				This course has educational videos, Please click on “Okay” to grant permission to play video in this session!
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={()=>{
						hideWelcomModalFn()
					}}>
						Okay
                            </Button>
				</Modal.Footer>
			</Modal>
		</LoadingOverlay>
		{/* <div className="custom-toast">
			<div className="userInitials">PV</div>
			<p id="desc"><span className="userName">Prince Verma: </span>You stay classy San Deigo</p>
		</div> */}
		{/* <div >  
		<div >
		<div className="custom-toast">
        <Toast className="mb-20 toastBox" onClose={() => setShowToast(false)} show={showToast} delay={2000} autohide >
          <Toast.Header>
		  <div className="userInitials">PV</div>
            <div className="mr-auto">
			<div className="toastTitle">
				<strong className="userName">Prince Verma</strong>
				<small>11 mins ago</small>
			</div>
			<p>
			This is simple Bootstrap Toast Example
			</p>
			</div>
          </Toast.Header>
        </Toast>  
        <Toast className="mb-20 toastBox" onClose={() => setShowToast(false)} show={showToast} delay={2000} autohide>
          <Toast.Header>
		  <div className="userInitials">PV</div>
            <div className="mr-auto">
			<div className="toastTitle">
				<strong className="userName">Prince Verma</strong>
				<small>11 mins ago</small>
			</div>
			<p>
			This is simple Bootstrap Toast Example
			</p>
			</div>
          </Toast.Header>
        </Toast> 
        <Toast className="mb-20 toastBox" onClose={() => setShowToast(false)} show={showToast} delay={2000} autohide>
          <Toast.Header>
		  <div className="userInitials">PV</div>
            <div className="mr-auto">
			<div className="toastTitle">
				<strong className="userName">Prince Verma</strong>
				<small>11 mins ago</small>
			</div>
			<p>
			This is simple Bootstrap Toast Example
			</p>
			</div>
          </Toast.Header>
        </Toast> 
		</div>		
        <Button onClick={() => setShowToast(true)}>Click to Show Toast</Button>
		</div>  
    </div> */}
		</div>
	);
};

// function reducer(state, action) {
// 	switch (action.type) {
// 		case 'studentData':
// 			return { studentData: action.payload };
// 		default:
// 			throw new Error();
// 	}
// }

export default Home;

